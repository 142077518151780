<style src="./BorderRadius.scss" global>:global(.cm-BorderRadius--small) {
  border-radius: 4px;
}

:global(.cm-BorderRadius--medium) {
  border-radius: 8px;
}

:global(.cm-BorderRadius--large) {
  border-radius: 16px;
}</style>
