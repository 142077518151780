<style src="./Grid.scss" global>:global(.cm-Grid) {
  padding-left: 10px;
  padding-right: 10px;
}
:global(.cm-Grid) :global(.cm-Grid-row) {
  display: flex;
  margin: 0;
  width: 100%;
  margin-bottom: 12px;
}
:global(.cm-Grid) :global(.cm-Grid-row:last-child) {
  margin-bottom: 0;
}
:global(.cm-Grid) :global(.cm-Grid-row) > :global(*) {
  margin-left: 6px;
  margin-right: 6px;
}
:global(.cm-Grid.cm-Grid--noPadding) {
  padding-left: 0;
  padding-right: 0;
}
:global(.cm-Grid.cm-Grid--noPadding) :global(.cm-Grid-row) :global(:first-child) {
  margin-left: 0;
}
:global(.cm-Grid.cm-Grid--noPadding) :global(.cm-Grid-row) :global(:last-child) {
  margin-right: 0;
}</style>
