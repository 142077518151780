<style src="./Toggle.scss" global>:global(.cm-Toggle) {
  border-radius: 16px;
  height: 32px;
  border-width: 2px;
  width: 51px;
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 1px;
  background: #e1e1e1;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
:global(.cm-Toggle::before) {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 50%;
  transition: left 0.2s;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1), 0px 3px 12px rgba(0, 0, 0, 0.16);
}
:global(.cm-Toggle.is-active) {
  background: #3880ff;
  border-color: #3880ff;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Toggle.is-active::before) {
  left: calc(100% - 28px);
}</style>
