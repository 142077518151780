<style src="./Text.scss" global>/* Wrap modifiers
 ========================================================================== */
/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
:global(.cm-Text--truncate) {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 2 */
:global(th.cm-Text--truncate),
:global(td.cm-Text--truncate) {
  max-width: 0;
}

/*
 * 1. Wrap long words onto the next line and break them if they are too long to fit
 * 2. Legacy `word-wrap` as fallback for `overflow-wrap`
 * 3. Fix `overflow-wrap` which doesn't work with table cells in Chrome, Opera, IE11 and Edge
 *    Must use `break-all` to support IE11 and Edge
 * Note: Not using `hyphens: auto;` because it hyphenates text even if not needed
 */
:global(.cm-Text--break) {
  /* 1 */
  overflow-wrap: break-word;
  /* 2 */
  word-wrap: break-word;
}

/* 3 */
:global(th.cm-Text--break),
:global(td.cm-Text--break) {
  word-break: break-all;
}</style>
