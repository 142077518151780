<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { each } from 'svelte/internal';
  import { templater } from '../../actions/templater.js';
  import SlideOver from '../SlideOver/SlideOver.svelte';
  const dispatch = createEventDispatcher();

  export let templates = {
    button: null,
    cancel: null,
    title: null,
    header: null,
    subText: null,
  };
  export let buttons = [];
  export let title;
  export let closeWithOverlay = false;

  let closed = false,
    clickblock = true;

  onMount(() => {
    setTimeout(() => {
      clickblock = false;
    }, 500);
  });

  function close(d) {
    if (clickblock) return;

    closed = true;
    setTimeout(() => {
      dispatch('destroy', d);
    }, 500);
  }
</script>

<div class="cm-SlideOver cm-ActionSheet" class:is-closed={closed}>
  <div
    class="cm-SlideOver-overlay"
    on:click={() => {
      if (closeWithOverlay) {
        close();
      }
    }}
  />
  <div
    class="cm-SlideOver-body {templates.topIcon ? 'cm-SlideOver-bodyIcon' : ''}"
  >
    {#if templates.topIcon}
      <div class="cm-ActionSheet-topIcon">
        <div use:templater={{ template: templates.topIcon, data: {} }} />
      </div>
    {/if}
    <div class="cm-ActionSheet-title">
      <div use:templater={{ template: templates.title, data: {} }}>
        {#if title}
          <div class="cm-ActionSheet-titleText" cm-font="Title / 16 Medium">
            {@html title}
          </div>
        {/if}
      </div>
    </div>
    <div class="cm-ActionSheet-buttons">
      {#each buttons as button, i}
        {#if button.sectionTitle}
          {#if i}
            <div
              style="
          border-width: 3px;
          margin-left: -24px;
          margin-right: -24px;
          margin: 12px -24px;
          "
              cm-border="Neutral / Athens Grey"
            />
          {/if}
          <div cm-font="Title / 14 Medium" style="margin: 16px 0 8px;">
            {button.sectionTitle}
          </div>
        {:else}
          <div
            class="cm-ActionSheet-button"
            on:click={() => {
              try {
                if (button.handler) button.handler();
              } catch (e) {}
              close(button);
            }}
          >
            <div use:templater={{ template: templates.button, data: button }}>
              <div class="cm-ActionSheet-buttonTemplate">
                {#if button.icon}
                  <div class="cm-ActionSheet-icon">
                    {@html button.icon}
                  </div>
                {/if}
                <div
                  class="cm-ActionSheet-text"
                  cm-color={button.textColor || 'Neutral / Mine Shaft'}
                >
                  {@html button.text}
                  {#if button.subText}
                    <div
                      cm-font="Body / 12 Regular"
                      cm-color={button.subTextColor || 'Neutral / Storm Grey'}
                    >
                      {@html button.subText}
                    </div>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        {/if}
      {/each}
    </div>
    <div
      class="ActionSheet-cancelButton"
      on:click={() => {
        close();
      }}
    >
      <div use:templater={{ template: templates.cancel, data: {} }}>
        <button
          class="cm-Button cm-Button--1 cm-Button--alternate cm-Width-1-1"
          cm-color="Neutral / Mine Shaft"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<style src="./ActionSheet.scss" global>:global(.cm-ActionSheet) :global(.ActionSheet-cancelButton) {
  margin-top: 14px;
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-topIcon) {
  position: absolute;
  top: -46px;
  left: 50%;
  transform: translateX(-50%);
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-title) {
  text-align: left;
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-title) :global(.cm-ActionSheet-titleText) {
  padding-bottom: 24px;
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-title) :global(.cm-Divider) {
  margin-left: -24px;
  margin-right: -24px;
  width: auto;
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-subtext) {
  text-align: left;
  color: #73767f;
  font-size: 10px;
  transform: translateY(-18px);
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-buttons) {
  margin-top: -14px;
  text-align: left;
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-buttons) :global(.cm-ActionSheet-button) {
  padding: 14px 0;
  cursor: pointer;
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-buttons) :global(.cm-ActionSheet-button) :global(.cm-ActionSheet-buttonTemplate) {
  display: flex;
  border: 0px solid transparent;
  background: white;
  width: 100%;
  align-items: center;
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-buttons) :global(.cm-ActionSheet-button) :global(.cm-ActionSheet-buttonTemplate) :global(.cm-ActionSheet-icon) {
  flex-basis: 40px;
  text-align: left;
  display: flex;
  align-items: center;
}
:global(.cm-ActionSheet) :global(.cm-ActionSheet-buttons) :global(.cm-ActionSheet-button) :global(.cm-ActionSheet-buttonTemplate) :global(.cm-ActionSheet-text) {
  transform: translateY(1px);
  font-size: 16px;
  flex-grow: 1;
  text-align: left;
}</style>
