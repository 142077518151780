<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  export let title = '';
  export let numSteps = 0;
  let closed = false;
  let percentage = 0;
  let progressBar = 0;
  const stepInterval = 1000;
  let progressBarInterval;

  progressBarInterval = setInterval(async () => {
    progressBar += stepInterval;
    if (progressBar >= numSteps * stepInterval + 1000) {
      clearInterval(progressBarInterval);
      progressBarInterval = null;
      close();
    } else {
      let denominator = 10 * numSteps;
      percentage = `${progressBar / denominator}%`;
    }
  }, stepInterval);

  function close(d) {
    closed = true;
    setTimeout(() => {
      dispatch('destroy', d);
    }, 500);
  }
</script>

<div class="cm-SlideOver" class:is-closed={closed}>
  <div class="cm-SlideOver-overlay" />
  <div class="cm-SlideOver-body">
    <div
      class="cm-Margin--bottom"
      style="text-align:center"
      cm-font="Title / 16 Medium"
    >
      {@html title}
    </div>

    <div class="cm-ProgressBar-primary" cm-background="Secondary / Dodger Blue">
      <div class="cm-ProgressBar-inner" style="width: {percentage}" />
    </div>
  </div>
</div>

<style src="./ProgressBar.scss" global>:global(.cm-ProgressBar) {
  display: inline-block;
  vertical-align: middle;
}

:global(.cm-ProgressBar),
:global(.cm-ProgressBar-inner) {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  width: 100%;
  height: 16px;
  position: relative;
}
:global(.cm-ProgressBar) :global(.cm-ProgressBar-inner),
:global(.cm-ProgressBar-inner) :global(.cm-ProgressBar-inner) {
  background: #fff;
  position: absolute;
  left: 0;
  width: 0px;
  transition: width 0.2s;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

:global(.cm-ProgressBar-primary) {
  display: inline-block;
  vertical-align: middle;
}

:global(.cm-ProgressBar-primary),
:global(.cm-ProgressBar-inner) {
  background: rgba(49, 117, 249, 0.3);
  border-radius: 30px;
  width: 100%;
  height: 16px;
  position: relative;
}
:global(.cm-ProgressBar-primary) :global(.cm-ProgressBar-inner),
:global(.cm-ProgressBar-inner) :global(.cm-ProgressBar-inner) {
  background: #3175f9;
  position: absolute;
  left: 0;
  width: 0px;
  transition: width 0.2s;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}</style>
