<style src="./Avatar.scss" global>:global(.cm-Avatar) {
  height: 1em;
  width: 1em;
  position: relative;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  background: #f4f5f8;
}
:global(.cm-Avatar:not(:first-child)) {
  margin-left: -0.5em;
}
:global(.cm-Avatar) :global(img) {
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  border-radius: 50%;
}

:global(.cm-Avatar-Group) {
  flex-wrap: nowrap;
  display: flex;
}
:global(.cm-Avatar-Group.cm-Avatar-GroupReverse) {
  direction: ltr;
  text-align: right;
}</style>
