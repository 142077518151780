<style src="./Comments.scss" global>:global(.cm-Comments) {
  display: flex;
  margin-bottom: 24px;
}
:global(.cm-Comments) :global(.cm-Comments-left) {
  flex-grow: 0;
  width: auto;
}
:global(.cm-Comments) :global(.cm-Comments-left.cm-Comments-bottom) {
  align-items: flex-end;
}
:global(.cm-Comments) :global(.cm-Comments-right) {
  margin-left: 12px;
  flex-grow: 1;
  min-width: 0;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) {
  border: 1px solid;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  position: relative;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) {
  margin: 12px 12px;
  color: #262626;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comments-user) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4286;
  margin-bottom: 5px;
  padding-right: 30px;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comments--time) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.3333;
  margin-left: 4px;
  color: #73767f;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comments-comment) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.4286;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comment-reactions) {
  display: inline-flex;
  gap: 6px;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.25;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comment-reactions.cm-Comment-time-rightSide) {
  justify-content: space-between;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comment-reactions) :global(.cm-Comment-reactions-leftSide) {
  display: flex;
  align-items: center;
  gap: 4px;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comment-reactions) :global(.cm-Comment-reactions-rightSide) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.3333;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comment-reactions) :global(.cm-Comment-reaction-icons) {
  display: contents;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.3333;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-text) :global(.cm-Comment-reactions) :global(.cm-Comment-reaction-icons) :global(.cm-Comment-reaction-icons-nested) {
  display: inline-flex;
  align-items: center;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-image) :global(img) {
  max-width: 100%;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(img) {
  border-radius: 10px;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-icon) {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-icon) > :global(svg),
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-icon) :global(i),
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-icon) :global(.cm-Icon) {
  display: inline-block;
  font-size: 20px;
  line-height: 12px;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-messageThumb) {
  right: 7px;
  bottom: 0px;
  transform: translateY(50%);
  position: absolute;
  border-radius: 12px;
  padding: 3px 6px;
  border: solid 1px;
  font-size: 10px;
  line-height: 11px;
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 1px;
  background: #fff;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-messageThumb) > :global(svg),
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-messageThumb) :global(i),
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-message) :global(.cm-Comments-messageThumb) :global(.cm-Icon) {
  display: inline-block;
  margin-right: 3px;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-bottom) {
  display: flex;
  margin-top: 11px;
  margin-left: 1px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.4286;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-bottom) :global(.cm-Comments-bottom--time) {
  flex-grow: 0;
  width: auto;
  margin-right: 17px;
  color: #73767f;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.3333;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-bottom) :global(.cm-Comments-bottom--like) {
  flex-grow: 0;
  width: auto;
  margin-right: 17px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3333;
}
:global(.cm-Comments) :global(.cm-Comments-right) :global(.cm-Comments-bottom) :global(.cm-Comments-bottom--reply) {
  flex-grow: 0;
  width: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3333;
}</style>
