<style src="./Position.scss" global>:global([class*=cm-Position--top]),
:global([class*=cm-Position--bottom]),
:global([class*=cm-Position--left]),
:global([class*=cm-Position--right]),
:global([class*=cm-Position--center]) {
  position: absolute !important;
  /* 1 */
  max-width: 100%;
}

/* Edges
 ========================================================================== */
/* Don't use `width: 100%` because it is wrong if the parent has padding. */
:global(.cm-Position--top) {
  top: 0;
  left: 0;
  right: 0;
}

:global(.cm-Position--bottom) {
  bottom: 0;
  left: 0;
  right: 0;
}

:global(.cm-Position--left) {
  top: 0;
  bottom: 0;
  left: 0;
}

:global(.cm-Position--right) {
  top: 0;
  bottom: 0;
  right: 0;
}

/* Corners
 ========================================================================== */
:global(.cm-Position--top-left) {
  top: 0;
  left: 0;
}

:global(.cm-Position--top-right) {
  top: 0;
  right: 0;
}

:global(.cm-Position--bottom-left) {
  bottom: 0;
  left: 0;
}

:global(.cm-Position--bottom-right) {
  bottom: 0;
  right: 0;
}

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 */
:global(.cm-Position--center) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box;
}

/* Vertical */
:global([class*=cm-Position--center-left]),
:global([class*=cm-Position--center-right]) {
  top: 50%;
  transform: translateY(-50%);
}

:global(.cm-Position--center-left) {
  left: 0;
}

:global(.cm-Position--center-right) {
  right: 0;
}

:global(.cm-Position--center-left-out) {
  right: 100%;
  width: max-content;
}

:global(.cm-Position--center-right-out) {
  left: 100%;
  width: max-content;
}

/* Horizontal */
:global(.cm-Position--top-center),
:global(.cm-Position--bottom-center) {
  left: 50%;
  transform: translateX(-50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box;
}

:global(.cm-Position--top-center) {
  top: 0;
}

:global(.cm-Position--bottom-center) {
  bottom: 0;
}

/* Cover
 ========================================================================== */
:global(.cm-Position--cover) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Utility
 ========================================================================== */
:global(.cm-Position--relative) {
  position: relative !important;
}

:global(.cm-Position--absolute) {
  position: absolute !important;
}

:global(.cm-Position--fixed) {
  position: fixed !important;
}

:global(.cm-Position--z-index) {
  z-index: 1;
}

/* Margin modifier
 ========================================================================== */
/*
 * Small
 */
:global(.cm-Position--small) {
  max-width: calc(100% - 30px);
  margin: 15px;
}

:global(.cm-Position--small.cm-Position--center) {
  transform: translate(-50%, -50%) translate(-15px, -15px);
}

:global(.cm-Position--small[class*=cm-Position--center-left]),
:global(.cm-Position--small[class*=cm-Position--center-right]) {
  transform: translateY(-50%) translateY(-15px);
}

:global(.cm-Position--small.cm-Position--top-center),
:global(.cm-Position--small.cm-Position--bottom-center) {
  transform: translateX(-50%) translateX(-15px);
}

/*
 * Medium
 */
:global(.cm-Position--medium) {
  max-width: calc(100% - 60px);
  margin: 30px;
}

:global(.cm-Position--medium.cm-Position--center) {
  transform: translate(-50%, -50%) translate(-30px, -30px);
}

:global(.cm-Position--medium[class*=cm-Position--center-left]),
:global(.cm-Position--medium[class*=cm-Position--center-right]) {
  transform: translateY(-50%) translateY(-30px);
}

:global(.cm-Position--medium.cm-Position--top-center),
:global(.cm-Position--medium.cm-Position--bottom-center) {
  transform: translateX(-50%) translateX(-30px);
}

/*
 * Large
 */
:global(.cm-Position--large) {
  max-width: calc(100% - 60px);
  margin: 30px;
}

:global(.cm-Position--large.cm-Position--center) {
  transform: translate(-50%, -50%) translate(-30px, -30px);
}

:global(.cm-Position--large[class*=cm-Position--center-left]),
:global(.cm-Position--large[class*=cm-Position--center-right]) {
  transform: translateY(-50%) translateY(-30px);
}

:global(.cm-Position--large.cm-Position--top-center),
:global(.cm-Position--large.cm-Position--bottom-center) {
  transform: translateX(-50%) translateX(-30px);
}

:global([class*=cm-Position--inline]) {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden;
}</style>
