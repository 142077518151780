<style src="./Font.scss" global>:global([cm-font="Title / 28 Bold"]) {
  font-style: normal;
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 1.2857;
}

:global([cm-font-i="Title / 28 Bold"]) {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 2.8rem !important;
  line-height: 1.2857 !important;
}

:global(p[cm-font="Title / 28 Bold"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Title / 28 Bold"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Title / 24 Semibold"]) {
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 1.3333;
}

:global([cm-font-i="Title / 24 Semibold"]) {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 2.4rem !important;
  line-height: 1.3333 !important;
}

:global(p[cm-font="Title / 24 Semibold"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Title / 24 Semibold"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Title / 20 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
}

:global([cm-font-i="Title / 20 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 2rem !important;
  line-height: 1.2 !important;
}

:global(p[cm-font="Title / 20 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Title / 20 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Title / 18 Medium"]) {
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.3333;
}

:global([cm-font-i="Title / 18 Medium"]) {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 1.8rem !important;
  line-height: 1.3333 !important;
}

:global(p[cm-font="Title / 18 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Title / 18 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Title / 16 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.25;
}

:global([cm-font-i="Title / 16 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.6rem !important;
  line-height: 1.25 !important;
}

:global(p[cm-font="Title / 16 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Title / 16 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Title / 14 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4286;
}

:global([cm-font-i="Title / 14 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  line-height: 1.4286 !important;
}

:global(p[cm-font="Title / 14 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Title / 14 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Title / 12 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3333;
}

:global([cm-font-i="Title / 12 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  line-height: 1.3333 !important;
}

:global(p[cm-font="Title / 12 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Title / 12 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Body / 16 Regular"]) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.25;
}

:global([cm-font-i="Body / 16 Regular"]) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1.6rem !important;
  line-height: 1.25 !important;
}

:global(p[cm-font="Body / 16 Regular"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Body / 16 Regular"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Body / 16 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.25;
}

:global([cm-font-i="Body / 16 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.6rem !important;
  line-height: 1.25 !important;
}

:global(p[cm-font="Body / 16 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Body / 16 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Body / 14 Regular"]) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.4286;
}

:global([cm-font-i="Body / 14 Regular"]) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1.4rem !important;
  line-height: 1.4286 !important;
}

:global(p[cm-font="Body / 14 Regular"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Body / 14 Regular"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Body / 14 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4286;
}

:global([cm-font-i="Body / 14 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  line-height: 1.4286 !important;
}

:global(p[cm-font="Body / 14 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Body / 14 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Body / 12 Regular"]) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.3333;
}

:global([cm-font-i="Body / 12 Regular"]) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1.2rem !important;
  line-height: 1.3333 !important;
}

:global(p[cm-font="Body / 12 Regular"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Body / 12 Regular"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Body / 12 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3333;
}

:global([cm-font-i="Body / 12 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  line-height: 1.3333 !important;
}

:global(p[cm-font="Body / 12 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Body / 12 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Body / 10 Regular"]) {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.3333;
}

:global([cm-font-i="Body / 10 Regular"]) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1rem !important;
  line-height: 1.3333 !important;
}

:global(p[cm-font="Body / 10 Regular"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Body / 10 Regular"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Button / 16 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1;
}

:global([cm-font-i="Button / 16 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.6rem !important;
  line-height: 1 !important;
}

:global(p[cm-font="Button / 16 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Button / 16 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Button / 16 Regular"]) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1;
}

:global([cm-font-i="Button / 16 Regular"]) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1.6rem !important;
  line-height: 1 !important;
}

:global(p[cm-font="Button / 16 Regular"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Button / 16 Regular"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Button / 14 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.1429;
}

:global([cm-font-i="Button / 14 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  line-height: 1.1429 !important;
}

:global(p[cm-font="Button / 14 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Button / 14 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Button / 14 Regular"]) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.1429;
}

:global([cm-font-i="Button / 14 Regular"]) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1.4rem !important;
  line-height: 1.1429 !important;
}

:global(p[cm-font="Button / 14 Regular"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Button / 14 Regular"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Button / 12 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3333;
}

:global([cm-font-i="Button / 12 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.2rem !important;
  line-height: 1.3333 !important;
}

:global(p[cm-font="Button / 12 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Button / 12 Medium"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Link / 12 Regular"]) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.3333;
}

:global([cm-font-i="Link / 12 Regular"]) {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1.2rem !important;
  line-height: 1.3333 !important;
}

:global(p[cm-font="Link / 12 Regular"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Link / 12 Regular"]:last-child) {
  margin-bottom: 0;
}

:global([cm-font="Link / 14 Medium"]) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.1429;
}

:global([cm-font-i="Link / 14 Medium"]) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  line-height: 1.1429 !important;
}

:global(p[cm-font="Link / 14 Medium"]) {
  margin-top: 0;
  margin-bottom: 0.1em;
}
:global(p[cm-font="Link / 14 Medium"]:last-child) {
  margin-bottom: 0;
}</style>
