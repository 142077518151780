<style src="./Badge.scss" global>:global(.cm-Badge) {
  padding: 2px 6px 0;
  text-align: center;
  display: inline-block;
  color: #fff;
  border-radius: 500px;
  vertical-align: middle;
  min-height: 20px;
  min-width: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3333;
}
:global(.cm-Badge.cm-Typing-Badge) {
  padding: 4px 6px 4px 4px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  border-width: 2px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Primary / CDLLife Blue"]) {
  background: #2e5ff6;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Primary / CDLLife Blue"]) {
  border-color: #2e5ff6;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Secondary / Dodger Blue"]) {
  background: #3880ff;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Secondary / Dodger Blue"]) {
  border-color: #3880ff;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Neutral / Mine Shaft"]) {
  background: #262626;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Neutral / Mine Shaft"]) {
  border-color: #262626;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Neutral / Storm Grey"]) {
  background: #73767f;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Neutral / Storm Grey"]) {
  border-color: #73767f;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Neutral / Manatee"]) {
  background: #989aa2;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Neutral / Manatee"]) {
  border-color: #989aa2;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Neutral / Mercury"]) {
  background: #e1e1e1;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Neutral / Mercury"]) {
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Neutral / Athens Grey"]) {
  background: #f4f5f8;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Neutral / Athens Grey"]) {
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Neutral / Light Grey"]) {
  background: #989aa2;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Neutral / Light Grey"]) {
  border-color: #989aa2;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Neutral / White"]) {
  background: #ffffff;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Neutral / White"]) {
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background=White]) {
  background: #ffffff;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color=White]) {
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Accent / Warning"]) {
  background: #ffbd55;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Accent / Warning"]) {
  border-color: #ffbd55;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Accent / Danger"]) {
  background: #ea4335;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Accent / Danger"]) {
  border-color: #ea4335;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge[cm-background="Accent / Success"]) {
  background: #009859;
}
:global(.cm-Badge.cm-Typing-Badge[cm-color="Accent / Success"]) {
  border-color: #009859;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Badge.cm-Typing-Badge) :global(img) {
  border-radius: 50%;
  position: relative;
  z-index: 1;
  object-fit: cover;
  width: 18px;
  height: 18px;
  border: 0px;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) {
  display: inline-flex;
  align-items: center;
  gap: 2px;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots) {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Primary / CDLLife Blue"]) {
  background: #2e5ff6;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Secondary / Dodger Blue"]) {
  background: #3880ff;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Neutral / Mine Shaft"]) {
  background: #262626;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Neutral / Storm Grey"]) {
  background: #73767f;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Neutral / Manatee"]) {
  background: #989aa2;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Neutral / Mercury"]) {
  background: #e1e1e1;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Neutral / Athens Grey"]) {
  background: #f4f5f8;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Neutral / Light Grey"]) {
  background: #989aa2;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Neutral / White"]) {
  background: #ffffff;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background=White]) {
  background: #ffffff;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Accent / Warning"]) {
  background: #ffbd55;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Accent / Danger"]) {
  background: #ea4335;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots[cm-background="Accent / Success"]) {
  background: #009859;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots:nth-child(2)) {
  animation-delay: 0.2s;
}
:global(.cm-Badge.cm-Typing-Badge) :global(.cm-TypingDots) :global(.cm-dots:nth-child(3)) {
  animation-delay: 0.4s;
}

@keyframes -global-loadingFade {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.1;
  }
}</style>
