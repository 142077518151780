<style src="./Button.scss" global>:global(.cm-Button) {
  outline: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  color: #fff;
  vertical-align: middle;
}
:global(.cm-Button[cm-color="Primary / CDLLife Blue"]) {
  background: #2e5ff6;
  color: #fff;
  border: solid 1px #2e5ff6;
}
:global(.cm-Button[cm-color="Primary / CDLLife Blue"].cm-Progress--alternate) {
  color: #2e5ff6;
  background: #fff;
}
:global(.cm-Button[cm-color="Secondary / Dodger Blue"]) {
  background: #3880ff;
  color: #fff;
  border: solid 1px #3880ff;
}
:global(.cm-Button[cm-color="Secondary / Dodger Blue"].cm-Progress--alternate) {
  color: #3880ff;
  background: #fff;
}
:global(.cm-Button[cm-color="Neutral / Mine Shaft"]) {
  background: #262626;
  color: #fff;
  border: solid 1px #262626;
}
:global(.cm-Button[cm-color="Neutral / Mine Shaft"].cm-Progress--alternate) {
  color: #262626;
  background: #fff;
}
:global(.cm-Button[cm-color="Neutral / Storm Grey"]) {
  background: #73767f;
  color: #fff;
  border: solid 1px #73767f;
}
:global(.cm-Button[cm-color="Neutral / Storm Grey"].cm-Progress--alternate) {
  color: #73767f;
  background: #fff;
}
:global(.cm-Button[cm-color="Neutral / Manatee"]) {
  background: #989aa2;
  color: #fff;
  border: solid 1px #989aa2;
}
:global(.cm-Button[cm-color="Neutral / Manatee"].cm-Progress--alternate) {
  color: #989aa2;
  background: #fff;
}
:global(.cm-Button[cm-color="Neutral / Mercury"]) {
  background: #e1e1e1;
  color: #fff;
  border: solid 1px #e1e1e1;
}
:global(.cm-Button[cm-color="Neutral / Mercury"].cm-Progress--alternate) {
  color: #e1e1e1;
  background: #fff;
}
:global(.cm-Button[cm-color="Neutral / Athens Grey"]) {
  background: #f4f5f8;
  color: #fff;
  border: solid 1px #f4f5f8;
}
:global(.cm-Button[cm-color="Neutral / Athens Grey"].cm-Progress--alternate) {
  color: #f4f5f8;
  background: #fff;
}
:global(.cm-Button[cm-color="Neutral / Light Grey"]) {
  background: #989aa2;
  color: #fff;
  border: solid 1px #989aa2;
}
:global(.cm-Button[cm-color="Neutral / Light Grey"].cm-Progress--alternate) {
  color: #989aa2;
  background: #fff;
}
:global(.cm-Button[cm-color="Neutral / White"]) {
  background: #ffffff;
  color: #fff;
  border: solid 1px #ffffff;
}
:global(.cm-Button[cm-color="Neutral / White"].cm-Progress--alternate) {
  color: #ffffff;
  background: #fff;
}
:global(.cm-Button[cm-color=White]) {
  background: #ffffff;
  color: #fff;
  border: solid 1px #ffffff;
}
:global(.cm-Button[cm-color=White].cm-Progress--alternate) {
  color: #ffffff;
  background: #fff;
}
:global(.cm-Button[cm-color="Accent / Warning"]) {
  background: #ffbd55;
  color: #fff;
  border: solid 1px #ffbd55;
}
:global(.cm-Button[cm-color="Accent / Warning"].cm-Progress--alternate) {
  color: #ffbd55;
  background: #fff;
}
:global(.cm-Button[cm-color="Accent / Danger"]) {
  background: #ea4335;
  color: #fff;
  border: solid 1px #ea4335;
}
:global(.cm-Button[cm-color="Accent / Danger"].cm-Progress--alternate) {
  color: #ea4335;
  background: #fff;
}
:global(.cm-Button[cm-color="Accent / Success"]) {
  background: #009859;
  color: #fff;
  border: solid 1px #009859;
}
:global(.cm-Button[cm-color="Accent / Success"].cm-Progress--alternate) {
  color: #009859;
  background: #fff;
}
:global(.cm-Button:active) {
  transform: translateY(1px);
  opacity: 0.93;
}
:global(.cm-Button.cm-Button--1) {
  border-radius: 30px;
  padding: 0 16px;
  height: 38px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate) {
  background: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.1429;
  color: #2e5ff6;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Neutral / Mine Shaft"]) {
  color: #262626;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Neutral / Storm Grey"]) {
  color: #73767f;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Neutral / Manatee"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Neutral / Mercury"]) {
  color: #e1e1e1;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Neutral / Light Grey"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Neutral / White"]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color=White]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Accent / Warning"]) {
  color: #ffbd55;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Accent / Danger"]) {
  color: #ea4335;
}
:global(.cm-Button.cm-Button--1.cm-Button--alternate[cm-color="Accent / Success"]) {
  color: #009859;
}
:global(.cm-Button.cm-Button--2) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.1429;
  border-radius: 30px;
  padding: 0 16px;
  height: 32px;
  line-height: 32px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate) {
  background: #ffffff;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6;
  border-color: #2e5ff6;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff;
  border-color: #3880ff;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Neutral / Mine Shaft"]) {
  color: #262626;
  border-color: #262626;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Neutral / Storm Grey"]) {
  color: #73767f;
  border-color: #73767f;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Neutral / Manatee"]) {
  color: #989aa2;
  border-color: #989aa2;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Neutral / Mercury"]) {
  color: #e1e1e1;
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Neutral / Light Grey"]) {
  color: #989aa2;
  border-color: #989aa2;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Neutral / White"]) {
  color: #ffffff;
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color=White]) {
  color: #ffffff;
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Accent / Warning"]) {
  color: #ffbd55;
  border-color: #ffbd55;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Accent / Danger"]) {
  color: #ea4335;
  border-color: #ea4335;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--2.cm-Button--alternate[cm-color="Accent / Success"]) {
  color: #009859;
  border-color: #009859;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--3) {
  border-radius: 30px;
  padding: 0 16px;
  height: 28px;
  line-height: 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Primary / CDLLife Blue"]) {
  background: rgba(46, 95, 246, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Secondary / Dodger Blue"]) {
  background: rgba(56, 128, 255, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Neutral / Mine Shaft"]) {
  background: rgba(38, 38, 38, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Neutral / Storm Grey"]) {
  background: rgba(115, 118, 127, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Neutral / Manatee"]) {
  background: rgba(152, 154, 162, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Neutral / Mercury"]) {
  background: rgba(225, 225, 225, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Neutral / Athens Grey"]) {
  background: rgba(244, 245, 248, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Neutral / Light Grey"]) {
  background: rgba(152, 154, 162, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Neutral / White"]) {
  background: rgba(255, 255, 255, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color=White]) {
  background: rgba(255, 255, 255, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Accent / Warning"]) {
  background: rgba(255, 189, 85, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Accent / Danger"]) {
  background: rgba(234, 67, 53, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--3.cm-Button--alternate[cm-color="Accent / Success"]) {
  background: rgba(0, 152, 89, 0.5);
  border-color: transparent;
}
:global(.cm-Button.cm-Button--5) {
  border-radius: 4px;
  padding: 0 16px;
  line-height: 30px;
  height: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3333;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate) {
  background: #ffffff;
  border-color: #2e5ff6;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Neutral / Mine Shaft"]) {
  color: #262626;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Neutral / Storm Grey"]) {
  color: #73767f;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Neutral / Manatee"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Neutral / Mercury"]) {
  color: #e1e1e1;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Neutral / Light Grey"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Neutral / White"]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color=White]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Accent / Warning"]) {
  color: #ffbd55;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Accent / Danger"]) {
  color: #ea4335;
}
:global(.cm-Button.cm-Button--5.cm-Button--alternate[cm-color="Accent / Success"]) {
  color: #009859;
}
:global(.cm-Button.cm-Button--6) {
  border-radius: 4px;
  padding: 0px 17px;
  line-height: 26px;
  height: 26px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.1429;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate) {
  background: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.1429;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Neutral / Mine Shaft"]) {
  color: #262626;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Neutral / Storm Grey"]) {
  color: #73767f;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Neutral / Manatee"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Neutral / Mercury"]) {
  color: #e1e1e1;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Neutral / Light Grey"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Neutral / White"]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color=White]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Accent / Warning"]) {
  color: #ffbd55;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Accent / Danger"]) {
  color: #ea4335;
}
:global(.cm-Button.cm-Button--6.cm-Button--alternate[cm-color="Accent / Success"]) {
  color: #009859;
}
:global(.cm-Button.cm-Button--7) {
  border-radius: 4px;
  padding: 0 16px;
  height: 52px;
  line-height: 52px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate) {
  background: #ffffff;
  border-color: #2e5ff6;
  border-style: solid;
  border-width: 1px;
  border-color: #262626;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Neutral / Mine Shaft"]) {
  color: #262626;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Neutral / Storm Grey"]) {
  color: #73767f;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Neutral / Manatee"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Neutral / Mercury"]) {
  color: #e1e1e1;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Neutral / Light Grey"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Neutral / White"]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color=White]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Accent / Warning"]) {
  color: #ffbd55;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Accent / Danger"]) {
  color: #ea4335;
}
:global(.cm-Button.cm-Button--7.cm-Button--alternate[cm-color="Accent / Success"]) {
  color: #009859;
}
:global(.cm-Button.cm-Button--8) {
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.25;
  line-height: 40px;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate) {
  background: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Neutral / Mine Shaft"]) {
  color: #262626;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Neutral / Storm Grey"]) {
  color: #73767f;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Neutral / Manatee"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Neutral / Mercury"]) {
  color: #e1e1e1;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Neutral / Light Grey"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Neutral / White"]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color=White]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Accent / Warning"]) {
  color: #ffbd55;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Accent / Danger"]) {
  color: #ea4335;
}
:global(.cm-Button.cm-Button--8.cm-Button--alternate[cm-color="Accent / Success"]) {
  color: #009859;
}
:global(.cm-Button.cm-Button--9) {
  border-radius: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.25;
  line-height: 45px;
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 1px;
  border-width: 1px;
  background: #fff;
  width: 100%;
  color: #262626;
  position: relative;
}
:global(.cm-Button.cm-Button--9) :global(i),
:global(.cm-Button.cm-Button--9) :global(svg) {
  font-size: 29px;
  left: 30px;
  line-height: 25px;
  height: 29px;
  width: auto;
  top: 8px;
  position: absolute;
  width: 30px;
  text-align: center;
}
:global(.cm-Button.cm-Button--9[cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6;
}
:global(.cm-Button.cm-Button--9[cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff;
}
:global(.cm-Button.cm-Button--9[cm-color="Neutral / Mine Shaft"]) {
  color: #262626;
}
:global(.cm-Button.cm-Button--9[cm-color="Neutral / Storm Grey"]) {
  color: #73767f;
}
:global(.cm-Button.cm-Button--9[cm-color="Neutral / Manatee"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--9[cm-color="Neutral / Mercury"]) {
  color: #e1e1e1;
}
:global(.cm-Button.cm-Button--9[cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8;
}
:global(.cm-Button.cm-Button--9[cm-color="Neutral / Light Grey"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--9[cm-color="Neutral / White"]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--9[cm-color=White]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--9[cm-color="Accent / Warning"]) {
  color: #ffbd55;
}
:global(.cm-Button.cm-Button--9[cm-color="Accent / Danger"]) {
  color: #ea4335;
}
:global(.cm-Button.cm-Button--9[cm-color="Accent / Success"]) {
  color: #009859;
}
:global(.cm-Button.cm-Button--10) {
  background: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.1429;
  color: #262626;
  border-radius: 10px;
  padding: 12px 16px;
  line-height: 16px;
}
:global(.cm-Button.cm-Button--10[cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6;
}
:global(.cm-Button.cm-Button--10[cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff;
}
:global(.cm-Button.cm-Button--10[cm-color="Neutral / Mine Shaft"]) {
  color: #262626;
}
:global(.cm-Button.cm-Button--10[cm-color="Neutral / Storm Grey"]) {
  color: #73767f;
}
:global(.cm-Button.cm-Button--10[cm-color="Neutral / Manatee"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--10[cm-color="Neutral / Mercury"]) {
  color: #e1e1e1;
}
:global(.cm-Button.cm-Button--10[cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8;
}
:global(.cm-Button.cm-Button--10[cm-color="Neutral / Light Grey"]) {
  color: #989aa2;
}
:global(.cm-Button.cm-Button--10[cm-color="Neutral / White"]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--10[cm-color=White]) {
  color: #ffffff;
}
:global(.cm-Button.cm-Button--10[cm-color="Accent / Warning"]) {
  color: #ffbd55;
}
:global(.cm-Button.cm-Button--10[cm-color="Accent / Danger"]) {
  color: #ea4335;
}
:global(.cm-Button.cm-Button--10[cm-color="Accent / Success"]) {
  color: #009859;
}
:global(.cm-Button.cm-Button-fab) {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  padding: 5px 18px;
  font-size: 40px;
  font-weight: 200;
  line-height: 1;
  position: relative;
}
:global(.cm-Button.cm-Button-fab[cm-color="Primary / CDLLife Blue"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Secondary / Dodger Blue"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Neutral / Mine Shaft"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Neutral / Storm Grey"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Neutral / Manatee"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Neutral / Mercury"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Neutral / Athens Grey"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Neutral / Light Grey"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Neutral / White"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color=White]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Accent / Warning"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Accent / Danger"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab[cm-color="Accent / Success"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-fab) :global(i) {
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 1.3333;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
:global(.cm-Button.cm-Button-fab-small) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.25;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  border: none;
  font-weight: 200;
  line-height: 2em;
  position: relative;
}
:global(.cm-Button.cm-Button-fab-small) :global(i) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
:global(.cm-Button.cm-Button-textFab) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.1429;
  height: 38px;
  line-height: 38px;
  padding: 0 20px;
}
:global(.cm-Button.cm-Button-textFab[cm-color="Primary / CDLLife Blue"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Secondary / Dodger Blue"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Neutral / Mine Shaft"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Neutral / Storm Grey"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Neutral / Manatee"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Neutral / Mercury"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Neutral / Athens Grey"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Neutral / Light Grey"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Neutral / White"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color=White]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Accent / Warning"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Accent / Danger"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-textFab[cm-color="Accent / Success"]) {
  box-shadow: 0px 4px 10px rgba(47, 89, 149, 0.5);
}
:global(.cm-Button.cm-Button-edit) {
  line-height: 30px;
  height: 30px;
  width: 80px;
  border-radius: 15px;
  background: #73767f;
}
:global(.cm-Button.cm-Button-edit) :global(i) {
  margin-right: 7px;
}
:global(.cm-Button.cm-Button--text) {
  /* 1 */
  padding: 0;
  background: none;
  position: relative;
  border: none !important;
  color: inherit;
}

:global(.cm-ButtonList) :global(.cm-Button) {
  margin-right: 12px;
}

:global(.cm-ButtonReaction) {
  text-align: center;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  padding: 4px 0.6em 4px 0.5em;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.1429;
  color: #262626;
  line-height: 1.35;
}
:global(.cm-ButtonReaction) :global(span) {
  font-size: 1.8rem;
  height: 1.8rem;
  width: 1.8rem;
}
:global(.cm-ButtonReaction) :global(.cm-ButtonReaction-icon) {
  font-size: 1.8rem;
  height: 1.8rem;
  width: 1.8rem;
  transform: translateY(-0.15rem);
}
:global(.cm-ButtonReaction) :global(.cm-ButtonReaction-number) {
  margin-left: 4px;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--Transparent) {
  color: #262626;
  background-color: transparent;
  border: none;
  margin-left: 0.75em;
  padding: 0;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--Transparent:first-child) {
  margin-left: 0;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--Neutral) {
  color: #262626;
  background-color: transparent;
  border: 1px solid #e1e1e1;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--Neutral:active), :global(.cm-ButtonReaction.cm-ButtonReaction--Neutral.cm-ButtonReaction--NeutralSelected) {
  background: #2e5ff6;
  border-color: #2e5ff6;
  border-style: solid;
  border-width: 1px;
  color: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground) {
  background: rgba(0, 0, 0, 0.2);
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Primary / CDLLife Blue"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Secondary / Dodger Blue"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Neutral / Mine Shaft"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Neutral / Mine Shaft"]) {
  color: #262626;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Neutral / Storm Grey"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Neutral / Storm Grey"]) {
  color: #73767f;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Neutral / Manatee"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Neutral / Manatee"]) {
  color: #989aa2;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Neutral / Mercury"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Neutral / Mercury"]) {
  color: #e1e1e1;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Neutral / Athens Grey"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Neutral / Light Grey"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Neutral / Light Grey"]) {
  color: #989aa2;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Neutral / White"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Neutral / White"]) {
  color: #ffffff;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color=White]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color=White]) {
  color: #ffffff;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Accent / Warning"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Accent / Warning"]) {
  color: #ffbd55;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Accent / Danger"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Accent / Danger"]) {
  color: #ea4335;
  background: #ffffff;
}
:global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground:active[cm-color="Accent / Success"]), :global(.cm-ButtonReaction.cm-ButtonReaction--ColoredBackground.cm-ButtonReaction--ColoredBackground-Selected[cm-color="Accent / Success"]) {
  color: #009859;
  background: #ffffff;
}

:global(.cm-Button-v2) {
  cursor: pointer;
  display: inline-block;
  border-radius: 2em;
  vertical-align: middle;
  height: 2.25em;
  padding-left: 1em;
  padding-right: 1em;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.1429;
  color: #262626;
  background: #f4f5f8;
  position: relative;
  border: none;
}
:global(.cm-Button-v2) :global(i) {
  margin-right: 0.5em;
}
:global(.cm-Button-v2) :global(i.float-right) {
  float: right;
  transform: translateY(1px);
  margin-right: 0;
  margin-left: 0.5em;
}
:global(.cm-Button-v2) :global(i.float-left) {
  float: left;
  transform: translateY(1px);
}
:global(.cm-Button-v2) :global(i.right) {
  margin: 0;
  position: absolute;
  right: 0.75em;
  top: 50%;
  transform: translateY(-50%);
}
:global(.cm-Button-v2) :global(i.left) {
  margin: 0;
  position: absolute;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
}
:global(.cm-Button-v2.cm-Width-1-1) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1;
}
:global(.cm-Button-v2.cm-Button--Primary) {
  background: #2e5ff6;
  color: #ffffff;
}
:global(.cm-Button-v2.cm-Button--Secondary) {
  background: #3880ff;
  color: #ffffff;
}
:global(.cm-Button-v2.cm-Button--Outline) {
  background: #ffffff;
  color: #2e5ff6;
  border-color: #2e5ff6;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Button-v2.cm-Button--Tertiery) {
  background: #f4f5f8;
}
:global(.cm-Button-v2.cm-Button--Dark) {
  background: #262626;
  color: #ffffff;
}
:global(.cm-Button-v2.cm-Button--Medium) {
  background: #989aa2;
  color: #ffffff;
}
:global(.cm-Button-v2.cm-Button--Light) {
  background: #989aa2;
  color: #262626;
}
:global(.cm-Button-v2.cm-Button--Modal) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1;
}
:global(.cm-Button-v2.cm-Button--Pill) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1;
  height: 1.875em;
  padding-left: 0.75em;
  padding-right: 0.75em;
}
:global(.cm-Button-v2.cm-Button--Pill.is-selected) {
  background: #2e5ff6;
  color: #ffffff;
}
:global(.cm-Button-v2.cm-Button--Pill:active) {
  background: #2e5ff6;
  color: #ffffff;
}
:global(.cm-Button-v2.cm-Button--IconOnly) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1;
  width: 2em;
  height: 2em;
  position: relative;
}
:global(.cm-Button-v2.cm-Button--IconOnly) :global(:first-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
:global(.cm-Button-v2.cm-Button--FAB) {
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 1.3333;
  box-shadow: 0px 4px 8px rgba(47, 89, 149, 0.5);
  height: 2.5em !important;
  transition: font-size 0.3s ease;
}
:global(.cm-Button-v2.cm-Button--FAB.cm-Button--IconOnly) {
  width: 2.5em !important;
}
:global(.cm-Button-v2.cm-Button--FAB.cm-Button--FAB-small) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1;
  box-shadow: 0px 4px 8px rgba(47, 89, 149, 0.5);
  transition: font-size 0.3s ease;
}
:global(.cm-Button-v2.is-disabled) {
  opacity: 0.5;
  pointer-events: none;
}</style>
