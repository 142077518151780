<style src="./Drawer.scss" global>:global(.cm-Drawer) :global(.cm-Drawer-overlay) {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(38, 38, 38, 0.2);
  inset: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  pointer-events: none;
}
:global(.cm-Drawer) :global(.cm-Drawer-body) {
  position: fixed;
  inset: 0;
  transform: translateX(-100%);
  background-color: #fff;
  padding: 16px;
  max-width: 300px;
  height: 100vh;
  overflow: auto;
  animation: slideout;
  animation-duration: 0.4s;
}
:global(.cm-Drawer.is-open) :global(.cm-Drawer-overlay) {
  display: block;
  opacity: 1;
  pointer-events: all;
}
:global(.cm-Drawer.is-open) :global(.cm-Drawer-body) {
  transform: translateX(0);
  animation-name: slidein;
  animation-duration: 0.4s;
}

@keyframes -global-slideout {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes -global-slidein {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}</style>
