<style src="./Dropdown.scss" global>:global(.cm-Dropdown) {
  position: relative;
  width: fit-content;
}
:global(.cm-Dropdown.cm-Dropdown--block) {
  width: auto;
}
:global(.cm-Dropdown.cm-Dropdown--block:after) {
  top: 18px;
}
:global(.cm-Dropdown) :global(.cm-Dropdown-label) {
  position: absolute;
  z-index: 9;
  top: 6.5px;
  left: 16px;
}
:global(.cm-Dropdown:after) {
  content: ">";
  color: #262626;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4286;
  transform: rotate(90deg);
  right: 19px;
  top: 10px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}
:global(.cm-Dropdown) :global(.cm-Dropdown-select) {
  appearance: none;
  display: block;
  height: 40px;
  padding-left: 16px;
  padding-top: 6px;
  margin: 0px;
  background: none;
  border: 1px solid #e1e1e1;
  word-break: normal;
}
:global(.cm-Dropdown) :global(.cm-Dropdown-select.cm-Dropdown-select--block) {
  display: block;
  width: 100%;
  width: 100%;
  position: relative;
  height: 52px;
  padding-top: 24px;
}</style>
