<style src="./MessageBar.scss" global>:global(.cm-MessageBar) {
  padding: 10px 0;
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 1px;
  border-width: 0.5px 0px 0px 0px;
}
:global(.cm-MessageBar) :global(.cm-MessageBar-text) {
  text-align: center;
  padding-bottom: 4px;
}
:global(.cm-MessageBar) :global(.cm-MessageBar-grid) {
  padding: 0 19px;
}
:global(.cm-MessageBar) :global(.cm-MessageBar-grid) :global(.cm-Grid-row) {
  gap: 10px;
}
:global(.cm-MessageBar) :global(.cm-MessageBar-iconContainer) {
  display: inline-block;
  position: relative;
  align-self: center;
}
:global(.cm-MessageBar) :global(.cm-MessageBar-icon) {
  font-size: 24px;
  margin-right: 0px !important;
  margin-left: 0px !important;
  max-width: 100%;
}
:global(.cm-MessageBar) :global(.cm-MessageBar-image) {
  position: relative;
}
:global(.cm-MessageBar) :global(.cm-MessageBar-image) :global(.cm-MessageBar-imageRemove) {
  position: absolute;
  top: 6px;
  right: 7px;
  background: #fff;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:global(.cm-MessageBar) :global(.cm-MessageBar-image) :global(img) {
  border-radius: 10px;
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 120px;
}</style>
