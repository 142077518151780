<style src="./SlideOver.scss" global>:global(.cm-SlideOver) {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
:global(.cm-SlideOver) :global(.cm-SlideOver-overlay) {
  position: absolute;
  background: rgba(38, 38, 38, 0.2);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  animation-duration: 0.5s;
  animation-name: fadein;
  z-index: 1;
}
:global(.cm-SlideOver) :global(.cm-SlideOver-body) {
  position: absolute;
  background: #fff;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border-radius: 12px 12px 0 0;
  padding: 24px;
  animation-duration: 0.5s;
  animation-name: slideup;
  z-index: 2;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}
:global(.cm-SlideOver) :global(.cm-SlideOver-body.cm-SlideOver-Badge) {
  padding: 0;
  overflow: visible !important;
}
:global(.cm-SlideOver) :global(.cm-SlideOver-body.cm-SlideOver-Badge) :global(.cm-SlideOver-innerBody) {
  position: relative;
}
:global(.cm-SlideOver) :global(.cm-SlideOver-body.cm-SlideOver-Badge) :global(.cm-SlideOver-innerBody) :global(.cm-SlideOver-icon) {
  position: absolute;
  top: -45px;
  z-index: 3;
  width: 100%;
  text-align: center;
}
:global(.cm-SlideOver) :global(.cm-SlideOver-body.cm-SlideOver-Badge) :global(.cm-SlideOver-innerBody) :global(.cm-SlideOver-innerText) {
  padding: 63px 16px 24px 16px;
}
:global(.cm-SlideOver) :global(.cm-SlideOver-body) :global(.cm-SlideOver-button) {
  padding: 10px;
}
:global(.cm-SlideOver) :global(.cm-SlideOver-body.cm-SlideOver-bodyIcon) {
  overflow: visible !important;
}
:global(.cm-SlideOver) :global(.cm-SlideOver-closeButton) {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 16px;
  color: #73767f;
}
:global(.cm-SlideOver.is-closed) :global(.cm-SlideOver-overlay) {
  z-index: 3;
  opacity: 0;
}
:global(.cm-SlideOver.is-closed) :global(.cm-SlideOver-body) {
  animation-name: slidedown;
  transform: translateY(100%);
}
:global(.cm-SlideOver.is-closed) :global(.cm-SlideOver-body.cm-SlideOver-bodyIcon) {
  animation-name: slidedownwithicon;
  overflow: visible !important;
}
:global(.cm-SlideOver.cm-SlideOver--withCloseButton) :global(.cm-SlideOver-body) {
  padding: 0;
}
:global(.cm-SlideOver.cm-SlideOver--withCloseButton) :global(.cm-SlideOver-bodyInner) {
  max-height: calc(100vh - 40px);
  overflow: auto;
  padding: 50px 16px 24px;
}

@keyframes -global-fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes -global-slideup {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes -global-slidedown {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes -global-slidedownwithicon {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(calc(100% + 40px));
  }
}</style>
