<style src="./BoxShadow.scss" global>:global([cm-box-shadow=FAB]) {
  box-shadow: 0px 8px 16px rgba(30, 97, 214, 0.2);
}

:global([cm-box-shadow-i=FAB]) {
  box-shadow: 0px 8px 16px rgba(30, 97, 214, 0.2) !important;
}

:global([cm-box-shadow="Onboarding Value Prop Card Shadow"]) {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);
}

:global([cm-box-shadow-i="Onboarding Value Prop Card Shadow"]) {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35) !important;
}

:global([cm-box-shadow="Feed/Notification_Card"]) {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

:global([cm-box-shadow-i="Feed/Notification_Card"]) {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
}</style>
