<style src="./IconFooter.scss" global>:global(.cm-IconFooter) {
  background: #f4f5f8;
  display: flex;
  justify-content: flex-start;
}
:global(.cm-IconFooter) :global(.cm-IconFooter-icon) {
  padding: 16px 0;
  width: 54px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global(.cm-IconFooter) :global(.cm-IconFooter-icon) :global(svg),
:global(.cm-IconFooter) :global(.cm-IconFooter-icon) :global(i) {
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  color: #2e5ff6;
}</style>
