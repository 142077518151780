<style src="./ListItem.scss" global>:global(.cm-ListItem-breakLine) {
  width: 100%;
  margin: 7px 0;
  border-top: 1px solid #e1e1e1;
}

:global(.cm-ListItem) {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 16px;
}
:global(.cm-ListItem.cm-ListItem-break) {
  padding: 8px 10px;
  justify-content: space-between;
}
:global(.cm-ListItem:last-child) {
  margin-bottom: 0;
}
:global(.cm-ListItem) :global(.cm-ListItem-left) {
  flex-grow: 0;
  width: auto;
}
:global(.cm-ListItem) :global(.cm-ListItem-middle) {
  flex-grow: 1;
  width: auto;
  margin: 0 12px;
  min-width: 0;
}
:global(.cm-ListItem) :global(.cm-ListItem-middle:first-child) {
  margin-left: 0;
}
:global(.cm-ListItem) :global(.cm-ListItem-inline) {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}
:global(.cm-ListItem) :global(.cm-ListItem-right) {
  flex-grow: 0;
  width: auto;
  color: #989aa2;
}
:global(.cm-ListItem) :global(.cm-ListItem-right.cm-ListItem-right--bigNum) {
  color: #262626;
  font-size: 24px;
}
:global(.cm-ListItem.cm-ListItem--padded) {
  margin-bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
}
:global(.cm-ListItem.cm-ListItem--bordered) {
  margin-bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 0.5px solid #e1e1e1;
}
:global(.cm-ListItem.cm-ListItem--bordered:first-child) {
  border-top: 0.5px solid #e1e1e1;
}
:global(.cm-ListItem.cm-ListItem--condensed) {
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
}</style>
