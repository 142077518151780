<style src="./Feedback.scss" global>:global(.cm-Feedback) {
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  background: rgba(38, 38, 38, 0.1);
  color: #262626;
}
:global(.cm-Feedback) :global(.cm-Feedback-message) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4286;
  width: 100%;
  padding: 12px 0;
}
:global(.cm-Feedback) :global(.cm-Feedback-buttons) {
  padding-left: 16px;
  white-space: nowrap;
}
:global(.cm-Feedback) :global(.cm-Feedback-buttons) :global(button) {
  margin-left: 9px;
}
:global(.cm-Feedback) :global(.cm-Feedback-buttons) :global(button:first-child) {
  margin-left: 0;
}
:global(.cm-Feedback) :global(.cm-Feedback-buttons) :global(.cm-Button--6) {
  color: #fff;
  background: #73767f;
}
:global(.cm-Feedback) :global(.cm-Feedback-buttons) :global(.cm-Button--6.cm-Button--alternate) {
  color: #73767f;
  background: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Feedback.cm-Feedback--success) {
  background: rgba(0, 152, 89, 0.1);
  color: #009859;
}
:global(.cm-Feedback.cm-Feedback--success) :global(.cm-Button--6) {
  color: #fff;
  background: #009859;
}
:global(.cm-Feedback.cm-Feedback--success) :global(.cm-Button--6.cm-Button--alternate) {
  color: #009859;
  background: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Feedback.cm-Feedback--warning) {
  background: rgba(255, 189, 85, 0.1);
  color: #73767f;
}
:global(.cm-Feedback.cm-Feedback--warning) :global(.cm-Button--6) {
  color: #fff;
  background: #ffbd55;
}
:global(.cm-Feedback.cm-Feedback--warning) :global(.cm-Button--6.cm-Button--alternate) {
  color: #ffbd55;
  background: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Feedback.cm-Feedback--error) {
  background: rgba(234, 67, 53, 0.1);
  color: #ea4335;
}
:global(.cm-Feedback.cm-Feedback--error) :global(.cm-Button--6) {
  color: #fff;
  background: #ea4335;
}
:global(.cm-Feedback.cm-Feedback--error) :global(.cm-Button--6.cm-Button--alternate) {
  color: #ea4335;
  background: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
}
:global(.cm-Feedback.cm-Feedback--info) {
  background: rgba(56, 128, 255, 0.1);
  color: #3880ff;
}
:global(.cm-Feedback.cm-Feedback--info) :global(.cm-Button--6) {
  color: #fff;
  background: #3880ff;
}
:global(.cm-Feedback.cm-Feedback--info) :global(.cm-Button--6.cm-Button--alternate) {
  color: #3880ff;
  background: #f4f5f8;
  border-color: #f4f5f8;
  border-style: solid;
  border-width: 1px;
}

:global(.cm-Feedback--updated) {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
}
:global(.cm-Feedback--updated) :global([cm-color="Primary / CDLLife Blue"]) {
  color: #2e5ff6 !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Primary / CDLLife Blue"]) {
  background: #2e5ff6 !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Secondary / Dodger Blue"]) {
  color: #3880ff !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Secondary / Dodger Blue"]) {
  background: #3880ff !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Neutral / Mine Shaft"]) {
  color: #262626 !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Neutral / Mine Shaft"]) {
  background: #262626 !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Neutral / Storm Grey"]) {
  color: #73767f !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Neutral / Storm Grey"]) {
  background: #73767f !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Neutral / Manatee"]) {
  color: #989aa2 !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Neutral / Manatee"]) {
  background: #989aa2 !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Neutral / Mercury"]) {
  color: #e1e1e1 !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Neutral / Mercury"]) {
  background: #e1e1e1 !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Neutral / Athens Grey"]) {
  color: #f4f5f8 !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Neutral / Athens Grey"]) {
  background: #f4f5f8 !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Neutral / Light Grey"]) {
  color: #989aa2 !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Neutral / Light Grey"]) {
  background: #989aa2 !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Neutral / White"]) {
  color: #ffffff !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Neutral / White"]) {
  background: #ffffff !important;
}
:global(.cm-Feedback--updated) :global([cm-color=White]) {
  color: #ffffff !important;
}
:global(.cm-Feedback--updated) :global([cm-background=White]) {
  background: #ffffff !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Accent / Warning"]) {
  color: #ffbd55 !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Accent / Warning"]) {
  background: #ffbd55 !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Accent / Danger"]) {
  color: #ea4335 !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Accent / Danger"]) {
  background: #ea4335 !important;
}
:global(.cm-Feedback--updated) :global([cm-color="Accent / Success"]) {
  color: #009859 !important;
}
:global(.cm-Feedback--updated) :global([cm-background="Accent / Success"]) {
  background: #009859 !important;
}
:global(.cm-Feedback--updated) :global(.cm-Feedback--updated-actions) {
  display: flex;
  align-items: center;
  gap: 16px;
}</style>
