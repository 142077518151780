<style src="./Card.scss" global>:global(.cm-Card) {
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;
}
:global(.cm-Card.cm-Card--columns) {
  padding: 0;
  display: flex;
  align-items: stretch;
}
:global(.cm-Card.cm-Card--columns) > :global(*) {
  padding: 16px;
}
:global(.cm-Card.cm-Card--columns) > :global(*:first-child) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
:global(.cm-Card.cm-Card--columns) > :global(*:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
:global(.cm-Card) :global(.Card-leftNumber) {
  text-align: center;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e5ff6;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
}
:global(.cm-Card.cm-Card--withHeader) {
  padding: 0;
}
:global(.cm-Card.cm-Card--withHeader) :global(.cm-Card-header) {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
:global(.cm-Card.cm-Card--withHeader) :global(.cm-Card-body) {
  padding: 16px 16px;
}
:global(.cm-Card.cm-Card--margin-1) {
  margin-bottom: 4px;
}
:global(.cm-Card.cm-Card--margin-2) {
  margin-bottom: 8px;
}
:global(.cm-Card.cm-Card--margin-3) {
  margin-bottom: 12px;
}
:global(.cm-Card:last-child) {
  margin-bottom: 0;
}

:global(.cm-LC-Message) {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e1e1e1;
  margin-bottom: 0.5em;
  width: 70vw;
  max-width: 500px;
}
:global(.cm-LC-Message.is-inset) {
  width: 100%;
  max-width: none;
  margin-bottom: 0;
}
:global(.cm-LC-Message.is-fullWidth) {
  width: 100%;
  max-width: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0 !important;
}
:global(.cm-LC-Message.is-poll) {
  background-color: #2e5ff6;
  color: white;
}
:global(.cm-LC-Message.is-poll) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topLeft) :global(.cm-LC-Message-top-name) {
  color: #ffffff;
}
:global(.cm-LC-Message.is-poll) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topLeft) :global(.cm-LC-Message-top-name) :global(.cm-LC-Message-top-firstLast) {
  color: #ffffff;
}
:global(.cm-LC-Message.is-poll) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topLeft) :global(.cm-LC-Message-top-name) :global(.cm-LC-Message-top-username) {
  color: #ffffff;
}
:global(.cm-LC-Message.is-poll) :global(.cm-LC-Message-top) :global(.cm-LC-Message-top-timestamp) {
  color: #ffffff;
}
:global(.cm-LC-Message.is-poll) :global(.cm-ListItem-breakLine) {
  opacity: 0.5;
}
:global(.cm-LC-Message.temp-bg-view-off-topic) {
  background-color: rgba(46, 95, 246, 0.1) !important;
}
:global(.cm-LC-Message) :global(.is-offTopic) {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px !important;
  height: 80px;
  border-radius: 8px;
  padding: 16px;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-top) {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template: "left right";
  grid-template-columns: auto max-content;
  gap: 6px;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topLeft) {
  grid-area: left;
  display: grid;
  grid-template: "name timestamp";
  grid-template-columns: minmax(auto, max-content) max-content;
  align-items: baseline;
  gap: 6px;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topLeft) :global(.cm-LC-Message-top-name) {
  grid-area: name;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #989aa2;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topLeft) :global(.cm-LC-Message-top-name) :global(.cm-LC-Message-top-firstLast) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4286;
  color: #262626;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topLeft) :global(.cm-LC-Message-top-name) :global(.cm-LC-Message-top-username) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.4286;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topLeft) :global(.cm-LC-Message-top-timestamp) {
  grid-area: timestamp;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.3333;
  color: #989aa2;
  margin: 0;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topRight) {
  grid-area: right;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.4286;
  color: #989aa2;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-top) :global(.cm-LC-Message-topRight) :global(.cm-LC-Message-topActions) {
  display: inline-flex;
  align-items: center;
  gap: 2px;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.25;
  width: 100%;
  margin: 0.5em 0;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: left;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(p) {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(img) {
  border-radius: 4px;
  object-fit: cover;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(.cm-LC-Message-medias) {
  display: grid;
  gap: 4px;
  width: 100%;
  padding: 4px 0px;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(.cm-LC-Message-medias.is-single) {
  grid-template-columns: 1fr;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(.cm-LC-Message-medias.is-single) :global(.cm-LC-Message-media) {
  aspect-ratio: 5/4; /* defining the aspect ratio of the image */
  object-fit: cover;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(.cm-LC-Message-medias.is-triple) {
  grid-template-columns: 1fr 1fr 1fr;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(.cm-LC-Message-medias) :global(.cm-LC-Message-media) {
  position: relative;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(.cm-LC-Message-medias) :global(.cm-LC-Message-media.has-overlay) {
  filter: brightness(0.3) grayscale(0.9);
  overflow: hidden;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(.cm-LC-Message-medias) :global(.cm-LC-Message-media) :global(.cm-LC-Message-imageOverlay) {
  position: absolute;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6509803922);
}
:global(.cm-LC-Message) :global(.cm-LC-Message-body) :global(.cm-LC-Message-medias) :global(.cm-LC-Message-media) :global(.cm-LC-Message-imageOverlayNum) {
  z-index: 99999;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 1.5em;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-poll-answers) {
  padding-bottom: 16px;
  gap: 4px;
  display: flex;
  flex-direction: column;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-poll-answers) :global(.poll-answers) {
  font-size: 1.4rem !important;
  font-weight: 500;
  line-height: 1.6rem;
  min-height: 40px;
  height: fit-content;
  border-radius: 12px;
  padding: 5px;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-poll-answers) :global(.poll-results) {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: flex-start;
  gap: 12px;
  font-weight: 500;
  line-height: 1.6rem;
  padding-top: 12px;
  margin-bottom: 6px;
  font-size: 1.4rem;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-poll-answers) :global(.poll-results) :global(.poll-percentage) {
  width: 37px;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-poll-answers) :global(.poll-results) :global(.poll-answer-text) {
  text-align: start;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-poll-answers) :global(.poll-progress-wrapper) {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-left: 43px;
  padding-right: 4px;
}
:global(.cm-LC-Message) :global(.cm-LC-Message-poll-answers) :global(.poll-progress-wrapper) :global(.cm-ProgressBar) {
  width: 100%;
}
:global(.cm-LC-Message) :global(hr) {
  border: 1px solid #e1e1e1;
  opacity: 0.6;
}</style>
