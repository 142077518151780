<style src="./Navbar.scss" global>:global(.cm-Navbar) {
  color: #262626;
  position: relative;
  width: 100%;
  display: block;
  border-bottom: 0.5px solid #e1e1e1;
  background: #fff;
  height: 49px;
  height: calc(env(safe-area-inset-top) + 49px);
}
:global(.cm-Navbar.cm-Navbar--noBorder) {
  border: none;
}
:global(.cm-Navbar) :global(.cm-Navbar-body) {
  position: relative;
}
:global(.cm-Navbar) :global(.cm-Navbar-start),
:global(.cm-Navbar) :global(.cm-Navbar-title),
:global(.cm-Navbar) :global(.cm-Navbar-end) {
  padding-top: 5px;
  padding-top: calc(env(safe-area-inset-top) + 5px);
}
:global(.cm-Navbar) :global(.cm-Navbar-start) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1;
  left: 16px;
  top: 0;
  position: absolute;
}
:global(.cm-Navbar) :global(.cm-Navbar-start) :global(.fal) {
  line-height: 44px;
}
:global(.cm-Navbar) :global(.cm-Navbar-start) > :global(*) {
  line-height: 44px;
}
:global(.cm-Navbar) :global(.cm-Navbar-end) {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1;
  right: 20px;
  top: 0;
  line-height: 44px;
  position: absolute;
}
:global(.cm-Navbar) :global(.cm-Navbar-end) > :global(*) {
  line-height: 44px;
}
:global(.cm-Navbar) :global(.cm-Navbar-title) {
  left: 60px;
  right: 60px;
  top: 0;
  text-align: center;
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  line-height: 44px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
:global(.cm-Navbar[cm-background]) {
  border: none;
}
:global(.cm-Navbar[cm-background-i]) {
  border: none;
}

:global(.cm-NavbarFollowup) {
  padding: 16px;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded) {
  padding: 0;
  position: relative;
  height: 10px;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded:after) {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: var(--cm-NavbarFollowup-height, 150px);
  z-index: -1;
  border-radius: 0px 0px 15px 15px;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Primary / CDLLife Blue"]:after) {
  background: #2e5ff6;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Secondary / Dodger Blue"]:after) {
  background: #3880ff;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Neutral / Mine Shaft"]:after) {
  background: #262626;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Neutral / Storm Grey"]:after) {
  background: #73767f;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Neutral / Manatee"]:after) {
  background: #989aa2;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Neutral / Mercury"]:after) {
  background: #e1e1e1;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Neutral / Athens Grey"]:after) {
  background: #f4f5f8;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Neutral / Light Grey"]:after) {
  background: #989aa2;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Neutral / White"]:after) {
  background: #ffffff;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background=White]:after) {
  background: #ffffff;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Accent / Warning"]:after) {
  background: #ffbd55;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Accent / Danger"]:after) {
  background: #ea4335;
}
:global(.cm-NavbarFollowup.cm-NavbarFollowup--rounded[cm-background="Accent / Success"]:after) {
  background: #009859;
}</style>
